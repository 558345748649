<template>
  <div>
    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Amadurecimento Precoce"
              titleColor="#000000"
            >
              <template v-slot:conteudo>
                <p>
                  Sem o tempo lúdico da infância, ou seja, privadas da maneira
                  fundamental de expressão dessa fase da vida, as crianças
                  precisaram também conviver com o adoecimento dos familiares.
                  Os adolescentes, por sua vez, perderam as atividades
                  rotineiras e os coletivos aos quais pertenciam, estruturantes
                  nessa fase da vida, sendo obrigados a tornar-se adultos diante
                  das novas condições de vida a eles impostas. Boa parte das
                  crianças e adolescentes passou a ter preocupações com o estado
                  emocional de seus pais ou cuidadores, que estavam enfrentando
                  perdas semelhantes. Parte significativa dos atingidos foi
                  compulsoriamente expulsa das suas comunidades para o centro de
                  Mariana e outros núcleos urbanos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/conteudos_crianca_adolescente_14.png" alt="" />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Tainara Torres / Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-md-start align-center"
        >
          <img src="../../assets/conteudos_crianca_adolescente_15.png" alt="" />
          <p style="font-size: 0.6em; text-align: center;">
            Criança atingida em manifestação pela entrega dos reassentamentos.
            Foto: Joice Valverde, 2021.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  Além desse deslocamento físico, gostaríamos de destacar que as
                  crianças passaram por um deslocamento do lugar da infância,
                  tendo que viver um momento “adulto” de amadurecimento rápido,
                  interrompendo o processo natural de elaborar os conflitos
                  infantis e vivenciar a crise normal de uma adolescência. Isso
                  se manifesta em vários depoimentos em que os jovens demonstram
                  preocupação com os responsáveis, ou mesmo que passaram a
                  cuidar deles.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Já cansei de ver todos os dias minha mãe chorando, pra baixo. É
              muito difícil ter que ficar motivando a mim mesmo e a ela também.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 17 anos.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Desisti da festa de aniversário porque meu pai e minha mãe
              adoeceram. Minha mãe disse que faria a festa se eu quisesse, mas
              eu não quis. Eu não tava vendo graça sendo que meus pais estavam
              doentes.
            </p>
            <p style="font-size: 16px">
              Sexo feminino, 12 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Como eu sou novo, tenho que pensar, ser mais cabeça e ajudar minha
              avó em casa, não a preocupar porque estou doente. Tenho que
              segurar as tristezas por ser mais novo [...] O sofrimento é da
              família, de se preocupar mesmo com a comunidade, por gostar de lá,
              onde ficava direto ajudando um ao outro. [...] Preciso ajudar
              minha avó, mais velha, na saúde dela. Esse negócio de tristeza tem
              que segurar na hora da família. Não tem como voltar o que
              aconteceu.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 16 anos.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Tentei segurar a tristeza para ser forte e assim poder dar apoio a
              minha família, em especial às pessoas mais velhas que foram as que
              ficaram mais tristes. Dias após o rompimento, minha mente mudou um
              pouco e fiquei um pouco abalado, mas não deixei passar pras outras
              pessoas. Fiquei triste e angustiado de ver o distrito que gostava
              destruído.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 16 anos
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section
      class="container-fluid section__mb img__background d-flex justify-center align-center"
    >
      <v-row
        style="z-index: 20;"
        class="container d-flex justify-center align-center;"
      >
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Nas
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_tomadas_de_termo' }"
                  >Tomadas de Termo</router-link
                >, observa-se a angústia dessas crianças e adolescentes com a
                situação de suas famílias após o rompimento e recorrente postura
                de silenciamento e embotamento de sentimentos para evitar
                sobrecarregar os adultos que amam. Justamente na fase de vida em
                que necessitam ter a ilusão de que os pais são aqueles seres
                onipotentes e quase que onipresentes que os ‘protegem de todo o
                mal’, que deveria ser desmanchada gradativamente. Ao perceberem
                os pais tão desamparados e impotentes em meio a tamanha
                tragédia, podemos inferir que esse corte se deu abruptamente
                tirando a leveza desse momento de vida e uma rápida
                ‘adultificação’, necessitando cuidar de si e dos outros que
                também ficaram muito abalados com as perdas.
              </p>
            </template>
          </Card>

          <Card borderColor="#E06919" class="mt-5">
            <template v-slot:conteudo>
              <p>
                Para além da necessidade de suportar as situações em uma
                tentativa de não sobrecarregar os adultos, as falas dessas
                crianças e adolescentes trazem ainda evidentes oposições dos
                sentimentos descritos e mesmo percebidos nas entonações e
                expressões faciais quando tratam do período anterior ao
                rompimento da barragem e ao momento atual.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Continuo indo a Pedras, mas a comunidade não é a mesma, pois
              muitos moradores saíram de lá, o que foi muito triste. [...] Sinto
              tristeza ao passar pelas localidades atingidas e não ter o
              convívio que tinha antes.
            </p>
            <p style="font-size: 16px">
              Sexo feminino, 17 anos.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Voltei a Bento em uma festa, e a festa não foi tão boa. Tinha
              poucas pessoas e muita poeira, o sol estava muito quente, as
              estradas ainda não estavam arrumadas e precisei passar no meio do
              mato. Bento era a alegria de todo mundo e depois do rompimento não
              tem a mesma alegria, está tudo destruído.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 13 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Evito ir a Bento para preservar a imagem boa da comunidade que
              guardo na minha memória.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 16 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Chego lá e está tudo acabado e isso é triste demais. O rompimento
              da barragem destruiu as lembranças que tinha de Ponte do Gama.
              Hoje em dia, Ponte do Gama está vazia.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 16 anos.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Antes em Bento era tudo colorido [...]. Consegui localizar qual
              era o local das casas que conhecia por causa do meu pai, que
              mostrou. Sinto falta, pois em Bento era mais alegre [...] antes
              era muito feliz. Era tão bonito, chegava e ficava tão alegre em
              Bento e agora é triste e feio.
            </p>
            <p style="font-size: 16px">
              Sexo feminino, 8 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Costumava frequentar o rio e as cachoeiras da região para nadar,
              para pescar, para aproveitar o tempo. Eram lugares agradáveis de
              ir. Hoje em dia vejo a marca de lama nas árvores, é triste. Mas a
              cor do rio tem melhorado. Eu vim pra Mariana a contragosto, meu
              pai não queria sair de casa, mas a Defesa Civil colocou o Conselho
              Tutelar no meio das negociações e a gente teve que vir pra
              Mariana. Eu gostava mesmo de lá, a minha vida era lá, gostava do
              que tinha lá.
            </p>
            <p style="font-size: 16px">
              Sexo masculino, 14 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-space-between">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Os depoimentos dessas crianças e adolescentes falam sobre as
                comunidades, lugares investidos de sentidos, eram “alegres”,
                “coloridas”, “agradáveis” ou “bonitas” e, atualmente, “tudo está
                acabado e isso é triste demais”. O uso de adjetivos que exprimem
                suas emoções, evidenciam a associação profunda com o que esses
                lugares e a perda deles representam em suas vidas.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Desenvolvi ansiedade após o rompimento. Me considero muito
              estressada e explosiva.
            </p>
            <p style="font-size: 16px">
              Sexo feminino, 9 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="5" class="d-flex justify-center align-center">
          <img
            class="mr-3"
            width="70px"
            src="../../assets/conteudos_crianca_adolescente_card_plant.png"
            alt=""
          />

          <div class="ml-5">
            <p style="font-size: 16px">
              Pra mim é tudo na seriedade, não sei estar alegre e nem aceito
              brincadeiras. Me tornei uma pessoa muito fechada e tenho muita
              dificuldade para me relacionar com as pessoas devido à mudança
              brusca de vida que enfrentei e também por toda a dificuldade de
              lidar com a rejeição e discriminação que sofri e continuo sofrendo
              na cidade. Antes era mais alegre, tinha mais vontade de viver
              (começa a chorar).
            </p>
            <p style="font-size: 16px">
              Sexo feminino, 12 anos.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="5"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/conteudos_crianca_adolescente_16.png" alt="" />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Wan Campos, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#E06919">
              <template v-slot:conteudo>
                <p>
                  A mudança para a cidade de Mariana, nas casas de aluguel em
                  bairros que não se reconhecem e em escolas provisórias, leva
                  esses jovens à
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_crianca_adolescente_ser',
                    }"
                    >perda de referências</router-link
                  >. Os sentimentos e a forma como essas crianças e adolescentes
                  conseguem elaborá-los vão, nas auto-imagens trazidas durante
                  as tomadas de termo, sendo cristalizados como personalidade
                  “fechada”, “séria”, “triste”, “explosiva” e uma série de
                  outras características que encobrem o processo de sofrimento.
                </p>
                <p style="text-align: center; margin-bottom: 0;">
                  <strong>Autoria: Laís Jabace Maia<sup>1</sup></strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Cientista Social (UFMG), mestra e doutoranda em
              Planejamento Urbano e Regional (IPPUR/UFRJ). Desde 2020 é
              Coordenadora Operacional da Assessoria Técnica aos Atingidos e
              Atingidas pelo Rompimento da Barragem do Fundão em Mariana pela
              Cáritas Brasileira Regional Minas Gerais.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
};
</script>

<style scoped>
.img__background {
  position: relative;
  z-index: 10;
}

.img__background::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/conteudos_crianca_adolescente_section_background.png");
  top: 30%;
  width: 100%;
  height: 40%;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_crianca_adolescente_text_bg_01.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_crianca_adolescente_text_bg_02.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em;
}

.iframe__box {
  position: relative;
  padding-bottom: 56.1%;
  height: 0;
  overflow: hidden;
}

.iframe__box iframe,
.iframe__box object,
.iframe__box embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
